@import "../../utils/colors.scss";

.login_screen{
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color:$background;
  justify-content:center;
  align-items:center;
  
.login_container{
  width: 30%;
  height: 70%;
  border-radius:20px;
  background-color:$primary;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
}
.img_container{
  display: flex;
  justify-content:center;
  align-items:center;
  width:50%;
  height:50%;
  .logo{
    display: flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    object-fit: contain;
  } 
}
.form{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  width:90%;
  height:50%;
  input{
    width:90%;
    height:55px;
    border:1px solid $light;
    border-radius: 12px;
    background-color: transparent;
    padding:0 12px;
    margin-bottom: 30px;
    color: $light;
  }
  ::placeholder {
    color: $description;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: $description;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: $description;
  }
  .login_btn{
    width: 90%;
    height: 50px;
    color: $light;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    border-radius: 12px;
    background-color: $secondary;
    margin: 20px 0;
    cursor: pointer;
  }
}
}