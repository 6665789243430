@import "../../utils/colors.scss";

.notification_screen {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: $background;
  flex-direction: column;
  .title{
    font-size: 18px;
    font-weight: bold;
    color: $light;
    margin-top:50px;
    margin-bottom: 20px;
  }
  .notification_title{
    width: 90%;
    height: 50px;
    background-color:transparent;
    border: 1px solid $description;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    color: $light;
    font-size: 16px;

  }
  .notification_message{
    width: 90%;
    height: 150px;
    color: $light;
    font-size: 16px;
    background-color:transparent;
    border: 1px solid $description;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    align-items:flex-start;
    justify-content:flex-start;
    text-align: start;
    vertical-align: top;
  }
  .send_btn{
    display: flex;
    justify-content: center;
    align-items:center;
    width:250px;
    height:50;
    padding: 10px 20px;
    background-color:$secondary;
    border-radius: 10px;
    color:$light;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}
.username{
  font-size: 16px;
  color: $description;
  padding: 0px 0px 5px 0px;
  font-weight: bold;
}
.email{
  font-size: 16px;
  color: $description;
  padding: 5px 0px 15px 0px;
}