@import "../../utils/colors.scss";

.userlist_container{
  display: flex;
  width:85%;
  background-color:$background;
  flex-direction: column;
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 10vh;
    margin-bottom: 20px;
    .title{
      font-size: 14px;
      font-weight: bold;
      color: $secondary;
      margin-left: 20px;
    }
    .search_input{
      width:280px;
      margin-right:4vw;
      height: 40px;
      background-color:transparent;
      border-radius:8px;
      border:1px solid $light;
      padding:0px 10px;
      color:$light;
      font-size:14px;
    }
  }
  .userlist_table_container{
    width: 100%;
    .table {
      font-family: arial, sans-serif;
      color: $light;
      width: 95%;
      height: 40px;
      border-collapse: separate;
      border-spacing: 0 20px;
      display: flex;
      justify-content:flex-start;
      flex-direction: row;
    }
    .row{
      background-color: $primary;
      height: 60px;
      margin-top:20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-left: 6px solid $secondary;
      border-radius: 0px 12px 12px 0px;
    }
    .th{
      text-align: left;
      font-size: 14px;
      padding: 10px 0px;
      width: 85vw;
      background-color: transparent;

    }
    .align{
      display: flex;
      justify-content:flex-start;
      align-items:center;
    }
    .name{
      width:600px;
    }
    .action{
      width:500px;
      display: flex;
      justify-content:center;
      align-items:center;
    }
    .serial{
      width:150px;
      margin-left: 5px;
    }
    .view{
      background-color:$secondary;
      padding:6px 20px;
      border-radius:6px;
      cursor: pointer;
    }
    .loader{
      color:$light;
      font-size:18px;
      margin-top:20px;
      width:95%;
      display: flex;
      justify-content:center;
      align-items:center;
    }
  }
}