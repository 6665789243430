@import "../../utils/colors.scss";

.userdetails_screen{
  display: flex;
  width: 100%;
  height: 100%;
  background-color:$background;
  .content_container{
    width: 100%;
    height: 100%;
  }
}