.ql-toolbar.ql-snow {
  border: none;
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid #f7f7f7 !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.ql-container.ql-snow {
  height: 200px;
  border: none;
}
.ql-editor {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  background-color: #f7f7f7 !important;
}
.quill {
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}
.ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: 'small';
    font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: 'Normal';
    font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: 'Large';
    font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: 'Huge';
    font-size: 60px !important;
}
