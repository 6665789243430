@import "../../utils/colors.scss";

.userdetails_container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: $background;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 100px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 50px;
    .back_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: $secondary;
      cursor: pointer;
      margin-left: 10px;
    }
    .back_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: $primary;
      border-radius: 6px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
    
  }
  .details_container {
    width: 100%;
    margin-bottom: 40px;
    .details_title {
      font-size: 22px;
      font-weight: bold;
      color: $light;
      padding: 5px 0px;
      text-transform: capitalize;
    }
    .details_episode {
      font-size: 18px;
      font-weight: bold;
      color: $description;
      padding: 5px 0px;
      text-transform: capitalize;
    }
    .details_feedback {
      font-size: 18px;
      font-weight: bold;
      color: $description;
      padding: 5px 0px;
      text-transform: capitalize;
      margin-top: 40px;
    }
    
    .details_block {
      display: flex;
      flex-direction: row;
      width:100%;
      flex-wrap: wrap;
      .details_answer_block {
        width: 400px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-right: 20px;
        // background-color: $light;
        .answer_card {
          width: 400px;
          // height: 200px;
          background-color: $primary;
          border-radius: 20px;
          padding: 20px;
        }
        .question_description {
          font-size: 16px;
          font-weight: bold;
          color: $description;
          padding: 5px 0px;
          // text-transform: capitalize;
        }
        .question_title {
          font-size: 16px;
          font-weight: bold;
          color: $description;
          padding: 5px 0px;
          // text-transform: capitalize;
        }
        .question_subtitle {
          font-size: 12px;
          // font-weight: bold;
          color: $description;
          padding: 5px 0px;
          // text-transform: capitalize;
        }
        .question_date {
          font-size: 10px;
          // font-weight: bold;
          color: $description;
          padding: 5px 0px;
        }
        .answer {
          font-size: 14px;
          font-weight: bold;
          color: $secondary;
          padding: 5px 0px;
          // text-transform: capitalize;
          white-space: pre-wrap;
        }
      }
    }
  }

}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}
.table_container {
  width: 90%;
  background-color: $primary;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;
  text-transform: capitalize;
}
  .table_header {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: $secondary;
    border-bottom: 1px solid $description;
    padding-bottom: 20px;
  }

  .table_wrapper{
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 12px 0px;
    font-size: 16px;
    color: $light;
    align-items: center;
  }
  .table_season{
    width: 15%;
  }
  .table_episode{
    width: 15%;
    text-align: center;
  }
  .table_status{
    width: 15%;
    text-align: center;
  }
  .table_date{
    width: 25%;
    text-align: center;
  }
  .profile_block {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 60px;
    background-color: $primary;
    border-radius: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    .name {
      font-size: 16px;
      font-weight: bold;
      color: $light;
      padding: 10px 0px;
      text-transform: capitalize;
      margin-right: 50px;
    }
    .email {
      font-size: 14px;
      // font-weight: bold;
      color: $description;
      padding: 5px 0px;
      margin-right: 50px;
    }
  }
  .send_notification_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    padding: 10px 20px;
    background-color: $secondary;
    border-radius: 10px;
    color: $light;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 50px;
  }