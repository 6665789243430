@import "../../utils/colors.scss";
.result_container {
  width: 100vw;
  height: 100vh;
  background: $background;
  padding: 40px;
  color: white;
  .result_heading {
    font-size: 18px;
    font-weight: bold;
    color: $secondary;
    display: flex;
    height: fit-content;
    width: fit-content;
    // background-color: red;
  }
  .result_heading_text {
    padding-top: 2px;
  }
  .result_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    .result_header_text {
      width: 40%;
      .result_header_title {
        font-size: 24px;
        font-weight: 800;
        padding-bottom: 10px;
      }
      .result_header_description {
        color: $description;
      }
    }
    .result_send_notification_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      padding: 10px 20px;
      background-color: $secondary;
      border-radius: 10px;
      color: $light;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .result_table_container {
    .result_table_header {
      width: 100%;
      display: flex;
      padding: 20px 5px;
    }
    .result_table_body_container {
      overflow: scroll;
      height: 75vh;
    }
    .result_table_body {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
      padding: 20px 5px;
      background-color: $primary;
      border-left: 6px solid $secondary;
      border-radius: 0px 12px 12px 0px;
    }
    .result_table_serial {
      width: 5%;
    }
    .result_table_username {
      width: 20%;
    }
    .result_table_email {
      width: 30%;
    }
    .result_table_response {
      width: 20%;
    }
    .result_table_date {
      width: 20%;
    }
    .action {
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .serial {
      width: 150px;
      margin-left: 5px;
    }
    .view {
      background-color: $secondary;
      padding: 6px 20px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
.button_link {
  text-decoration: none !important;
}
.back_link {
  text-decoration: none !important;
  color: $secondary;
}
