@import "../../utils/colors.scss";

.navbar_container{
  // width: 100%;
  height: 100%;
  .logo_container{
    width: 100%;
    // background-color:yellow;
    .logo_img{
      width:14vw;
      object-fit: contain;
    }
  }
  .links_container{
    margin:40px;
    display: flex;
    flex-direction:column;
    li{
      display: flex;
      flex-direction: row;
      font-size: 16px;
      color: $light;
      align-items: center;
      margin: 20px 0px;
      cursor: pointer;
      .links{
        color: $light;
        text-decoration: none;
      }
      .link_img{
        width:20px;
        height:20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}