@import '../../utils/colors.scss';

.campaignlist_container {
  display: flex;
  width: 85%;
  background-color: $background;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 10vh;
    margin-bottom: 20px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: $secondary;
      margin-left: 20px;
    }
    .search_input {
      width: 280px;
      margin-right: 4vw;
      height: 40px;
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid $light;
      padding: 0px 10px;
      color: $light;
      font-size: 14px;
    }
  }
  .campaignlist_table_container {
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    .table {
      font-family: arial, sans-serif;
      color: $light;
      width: 95%;
      height: 40px;
      border-collapse: separate;
      border-spacing: 0 20px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }
    .row {
      background-color: $primary;
      height: 60px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-left: 6px solid $secondary;
      border-radius: 0px 12px 12px 0px;
    }
    .th {
      text-align: left;
      font-size: 14px;
      padding: 10px 0px;
      width: 500px;
      background-color: transparent;
      // background-color: red;
    }
    .align {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .name {
      width: 1500px;
    }
    .action {
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .serial {
      width: 150px;
      margin-left: 5px;
    }
    .view {
      background-color: $secondary;
      padding: 6px 20px;
      border-radius: 6px;
      cursor: pointer;
    }
    .response {
      background-color: transparent;
    }
    .loader {
      color: $light;
      font-size: 18px;
      margin-top: 20px;
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.button_link {
  text-decoration: none !important;
  color: $light;
}
.campaign_modal_container {
  .modal_header_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .modal_close_button_container {
      cursor: pointer;
    }
    .modal_header_text {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .modal_form_container {
    .modal_form_wrapper {
      padding-bottom: 20px;
      .modal_input_heading {
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .modal_form_submit_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .form_submit_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    padding: 10px 20px;
    background-color: $secondary;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .calendar {
    background-color: #f7f7f7;
    outline: 0px;
    border: 0px;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}
