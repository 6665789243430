@import '../../utils/colors.scss';

.notification_screen {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: $background;
  flex-direction: column;
  padding: 20px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: $light;
    // margin-top:50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .title_text {
      padding-bottom: 5px;
      color: $secondary;
    }
    cursor: pointer;
  }
  .notification_title {
    width: 90%;
    height: 50px;
    background-color: transparent;
    border: 1px solid $description;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    color: $light;
    font-size: 16px;
  }
  .notification_message {
    width: 90%;
    height: 150px;
    color: $light;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid $description;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    vertical-align: top;
  }
  .send_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50;
    padding: 10px 20px;
    background-color: $secondary;
    border-radius: 10px;
    color: $light;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
  .send_btn:visited {
    text-decoration: none !important;
  }
  .info_container{
    padding-bottom: 20px;
    .campaign_title{
      font-size: 18px;
        font-weight: bold;
        color: $light;
      padding: 5px 0px;
    }
    .username{
      font-size: 16px;
      color: $description;
      padding: 5px 0px;
      font-weight: bold;
    }
    .email{
      font-size: 16px;
      color: $description;
      padding: 5px 0px;
    }
  }
}
.notification_history_date {
  padding: 10px 4px;
  color: $secondary;  
  font-size: 14px;
  max-width: 30%;
  height: fit-content;
  // background-color: $primary;
  border-radius: 8px;
}
.notification_history_message {
  margin-top: 4px;
  padding: 10px 4px;
  color: $light;
  max-width: 30%;
  height: fit-content;
  // background-color: $primary;
  border-radius: 8px;
}

.notification_history_wrapper {
  margin: 10px;
  padding: 10px;
  color: $light;
  width: 90%;
  height: fit-content;
  background-color: $primary;
  border-radius: 8px;
}
.notification_history{
  overflow: scroll;
  height: 50%;
}
